import styled from '@emotion/styled'
import Label from '../inputs/Label'
import Text from '../inputs/Text'
import React, {Component, useEffect, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import router from 'next/router'
import tw from 'twin.macro'
import {useSessionStorageState} from '../../utils/useSessionStorageState'
import {lookupStorageKey} from '../../apollo/cache'
import {OrderQuery} from '../../apollo/queries'
import {Loading} from '../Loading'
import Button from '../Button'
import ShadowBox from '../layout/ShadowBox'
import Subtitle from '../headings/Subtitle'
import H2 from '../headings/H2'
import Image from 'next/image'

const Div = styled.div(tw`relative mb-6`)

const initialState = {
  authenticated: false,
  orderNumber: '',
  email: '',
  order: '',
  timestamp: 0,
}

const Lookup = () => {
  useEffect(() => {
    if (state.authenticated) {
      router.push(`/order/${state.orderNumber}`)
    }
  }, [])

  const [state, setState] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  // const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [fetchOrder, {loading}] = useLazyQuery(OrderQuery, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setState({
        ...state,
        authenticated: true,
        order: data.getOrder,
        timestamp: Date.now(),
      })

      router.push(`/order/${state.orderNumber}`)
    },
    onError(error) {
      setError(
        `No orders were found matching your id and email. If you believe this is a mistake, please refresh your browser and try again.`,
      )
    },
  })

  function submitHandler(e) {
    e.preventDefault()
    setError('')

    if (!state.orderNumber) {
      setError('Order Number is required')
      return
    }

    if (!state.email || !validateEmail(state.email)) {
      setError('Email address is required')
      return
    }

    fetchOrder({
      variables: {id: state.orderNumber, email: state.email},
    })
  }
  function handleChange(e) {
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    })
    setError('')
  }

  return (
    !state.authenticated && (
      <main tw="h-screen grid place-content-center background[linear-gradient(to bottom, #AEF5EE, #fff)]">
        {/* <div tw="absolute py-3 font[20px] color[white] background[red] text-center w-full">
          The Order Portal will be unavailable for scheduled
          maintenance from 1/10 - 1/14.
        </div> */}
        <ShadowBox
          tw="mt-0 max-w-lg sm:w-screen mx-auto flex-1 place-self-center width[calc(100vw - 2rem)]"
          style={{minWidth: 'clamp(300px, 440px, 40vw)'}}
        >
          <header tw="text-center">
            <Image
              alt="Dangle It Logo"
              width="160"
              height="53"
              tw="h-8 w-auto mx-auto text-center"
              src="/logo.png"
              quality={100}
            />
            <H2>Order Lookup</H2>
            <Subtitle>Let&apos;s find your order</Subtitle>
          </header>
          <form onSubmit={submitHandler} tw="mt-4">
            <Div>
              <Label id="orderNumber" text="Order Number:" />
              <Text
                type="text"
                name="orderNumber"
                onChange={handleChange}
                value={state.orderNumber}
              />
            </Div>
            <Div>
              <Label id="email" text="Email Address:" />
              <Text
                type="email"
                name="email"
                onChange={handleChange}
                value={state.email}
              />
            </Div>

            <div tw="text-center">
              <Button tw="bg-brand text-white rounded py-2 px-16 mx-auto uppercase flex items-center transition-all">
                Go
                {loading && <Loading tw="ml-2" />}
              </Button>
              <a
                tw="underline mt-4 text-xs inline-block opacity-80"
                href="https://dangleit.com/pages/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                Having trouble logging in?
              </a>
            </div>
            {error && (
              <p tw="mt-2 text-sm text-center text-red-600">
                {error}
              </p>
            )}
          </form>
        </ShadowBox>
      </main>
    )
  )
}

Lookup.displayName = 'OrderLookup'
export default Lookup

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
