import {TwStyle} from 'twin.macro'
interface ButtonProps {
  css?: TwStyle[]
  onClick?: any
  type?: 'submit' | 'reset'
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({children, disabled, ...props}) => {
  return (
    <button disabled={disabled} {...props}>
      {children}
    </button>
  )
}

export default Button
